'use strict';

// Check for required objects
window.digitalData = window.digitalData || {};
window.SN = window.SN || {};
SN.trackEvent = SN.trackEvent || function () {
    (SN.e = SN.e || []).push(arguments);
};

var productType;
var itemResult = [];
if (window.digitalData.page.product) { 
    if (window.digitalData.page.product.length && window.digitalData.page.product[0] && window.digitalData.page.product[0].productInfo) {
        productType = window.digitalData.page.product[0].productInfo.prodCategory;
    } else if (window.digitalData.page.product.productInfo) {
        productType = window.digitalData.page.product.productInfo.prodCategory;
    }
}

if (window.digitalData.transaction && window.digitalData.transaction.item && window.digitalData.transaction.item.length) { 
    var items = window.digitalData.transaction.item;
    items.forEach(function (item) {
        itemResult.push({
            prodSKU: item.SKU,
            prodName: item.productName
       });
    });
}

// Configure data to be tracked
var chatEventData = {
    brand: window.digitalData.page.pageInfo.siteID,
    pageName: window.digitalData.page.pageInfo.pageType,
    orderId: window.digitalData.transaction ? window.digitalData.transaction.transactionID : null,
    orderAmount: window.digitalData.transaction ? window.digitalData.transaction.total : null,
    loggedInCurrent: window.digitalData.user.loggedIn,
    customerFName: window.digitalData.user.firstName ? window.digitalData.user.firstName : null,
    customerEmail: window.digitalData.user.emailId ? window.digitalData.user.emailId : null,
    rewardsEligibility: window.digitalData.user.customerType,
    gender: window.digitalData.page.pageInfo.department,
    productType: productType || null,
    visitorCountry: window.digitalData.page.pageInfo.country,
    visitorLanguage: window.digitalData.page.pageInfo.language,
    cartTotalProductQuantity: window.digitalData.cart && window.digitalData.cart.cartNumItems,
    cartTotalAmount: window.digitalData.cart && window.digitalData.cart.cartMerchTotal,
    orderValueProduct: window.digitalData.transaction && window.digitalData.transaction.orderMerchValue,
    item: itemResult
};

module.exports = {
    trackEvent: function () {
        // Send data to [24]7
        if (typeof SN !== 'undefined') {
            SN.trackEvent('pageload', chatEventData);
        }
    }
};
